export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/about.jpg' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>Kidshare c'est quoi ?</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h3>Nos prises de parole</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <div key={`${d.name}-${i}`} className="hyperlink">
                            <li key={`${d.name}-${i}`}>
                            <a href={d.link}>{d.name}</a>
                            </li>
      	  								</div>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                        <div key={`${d.name}-${i}`} className="hyperlink">
                            <li key={`${d.name}-${i}`}>
                            <a href={d.link}>{d.name}</a>
                            </li>
      	  							</div>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
