import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Services = (props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/form');
	};

	return (
		<div id="services" className="text-center">
			<div className="container">
				<div className="section-title">
					<h2>Nous contacter</h2>
					<p>
						N'hesitez pas à prendre contact avec nous sur nos différentes
						plateformes
					</p>
					<button className="btn btn-custom-1 btn-lg" onClick={handleClick}>
						Formulaire de contact
					</button>
				</div>
				<div className="row">
					{props.data
						? props.data.map((d, i) => (
								<div key={`${d.name}-${i}`} className="col-md-4">
									{' '}
									<i className={d.icon}>
									</i>
									<div className="service-desc">
										<a href={d.link}><h3>{d.name}</h3></a>
										<p>{d.text}</p>
									</div>
								</div>
						  ))
						: 'loading'}
				</div>
			</div>
		</div>
	);
};
