import { useState } from 'react';
import emailjs from 'emailjs-com';

const initialState = {
	name: '',
	email: '',
	message: '',
};

export const Contact = (props) => {
	const [{ name, surname, email, subject, message, numero }, setState] =
		useState(initialState);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const clearState = () => setState({ ...initialState });

	const handleSubmit = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				'service_l5eo41k',
				'template_0u6dl7h',
				e.target,
				'ZmWTSgtlsisq-PTxQ'
			)
			.then(
				(result) => {
					//console.log(result.text);
					alert('Message envoyé, merci !');
					clearState();
				},
				(error) => {
					//console.log(error.text);
					alert("Quelque chose s'est mal passé, veuillez réessayer plus tard");
				}
			);
		e.target.reset();
	};
	return (
		<div>
			<div id="contact" style={{ height: '100%' }}>
				<div className="container">
					<div className="col-md-8">
						<div className="row">
							<div className="section-title">
								<h2>Nous contacter</h2>
								<p>
									Veuillez remplir le formulaire ci-dessous pour nous envoyer un
									e-mail et nous vous répondrons nous vous répondrons dans les
									plus brefs délais.
								</p>
							</div>
							<form name="sentMessage" validate onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input
												type="text"
												id="name"
												name="name"
												className="form-control"
												placeholder="Nom"
												required
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
										<div className="form-group">
											<input
												type="email"
												id="email"
												name="email"
												className="form-control"
												placeholder="Email"
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input
												type="text"
												id="surname"
												name="surname"
												className="form-control"
												placeholder="Prénom"
												required
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
										<div className="form-group">
											<input
												type="text"
												id="numero"
												name="numero"
												className="form-control"
												placeholder="Numéro de téléphone"
												//required
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
									</div>
								</div>
								<div className="form-group">
									<textarea
										name="subject"
										id="subject"
										className="form-control"
										rows="1"
										placeholder="Objet"
										required
										onChange={handleChange}
									></textarea>
									<p className="help-block text-danger"></p>
								</div>
								<div className="form-group">
									<textarea
										name="message"
										id="message"
										className="form-control"
										rows="4"
										placeholder="Message"
										required
										onChange={handleChange}
									></textarea>
									<p className="help-block text-danger"></p>
								</div>
								<div id="success"></div>
								<button type="submit" className="btn btn-custom btn-lg">
									Envoyer
								</button>
							</form>
						</div>
					</div>
					<div className="col-md-3 col-md-offset-1 contact-info">
						<div className="contact-item">
							<h3>Informations de contact</h3>
							<p>
								<span>
									<i className="fa fa-phone"></i> Téléphone
								</span>{' '}
								{props.data ? props.data.phone : '06 25 95 02 54'}
							</p>
						</div>
						<div className="contact-item">
							<p>
								<span>
									<i className="fa fa-envelope-o"></i> Email
								</span>{' '}
								{props.data ? props.data.email : 'lyli.semiai@gmail.com'}
							</p>
						</div>
					</div>
					<div className="col-md-12">
						<div className="row">
							<div className="social">
								<ul>
									<li>
										<a href={props.data ? props.data.facebook : '/'}>
											<i className="fa fa-facebook"></i>
										</a>
									</li>
									<li>
										<a href={props.data ? props.data.twitter : '/'}>
											<i className="fa fa-twitter"></i>
										</a>
									</li>
									<li>
										<a href={props.data ? props.data.youtube : '/'}>
											<i className="fa fa-youtube"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
