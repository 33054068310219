export const Footer = (props) => {
	return (
		<div>
			<div id="footer">
				<div className="container text-center">
					<p>
						&copy; 2023 Kidshare. Design by &nbsp;
						<a href="https://github.com/gkehren" rel="nofollow">
							Guillaume Kehren
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
